<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
  components: {
    Layout,
    EasyDataTable,
    // ReportStatus
  },
  data() {
    return {
      searchValueTable: "",
      addStaff: false,
      selectFilterSort: null,
      app_URL: process.env.VUE_APP_URL,
      headers: [
        { text: "Title", value: "title" },
        { text: "Email", value: "user.email" },
        { text: "Total Amount", value: "totalAmount" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actionBtn" },
      ],

      items: [],
      arrItem: [],
    };
  },
  mounted() {
    this.getAllInvoice();
  },
  created() {
    this.getAllInvoice();
  },
  methods: {
    getAllInvoice() {
      axios.post(this.app_URL + "api/getAllInvoice").then((res) => {
        this.arrItem = res.data;
      })
    },
    downloadInvoice(id) {
      axios.post(this.app_URL + "api/downloadInvoice", { id: id }, { responseType: 'arraybuffer' }).then(res => {
        let blob = new Blob([res.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'invoice.pdf'
        link.click();
        this.$store.state.isAccepted = true
        this.$store.state.notificationData = 'Invoice Downloaded Successfully'
        setTimeout(() => {
          this.$store.state.isAccepted = false
          this.$store.state.notificationData = null
        }, 2500)
      })
    },
    encode(id) {
      return encodeURIComponent(
        CryptoJS.AES.encrypt(String(id), "Secret Passphrase")
      );
    }
  },


};
</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark">All Invoice List</h2>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row mb-3 align-items-center">
            <div class="col-lg-8 mt-3 mt-md-0">

            </div>
            <div class="col-lg-4 text-end">
              <input type="text" v-model="searchValueTable" placeholder="Search..."
                class="customInputForm form-control" />
            </div>
          </div>
          <EasyDataTable :headers="headers" :items="arrItem" :search-value="searchValueTable" border-cell
            theme-color="#df6a0d" :rows-per-page="10" buttons-pagination
            table-class-name="table-custom-style noWrapHeading">
            <template #item-Email="{ email }">
              <p class="mb-0 text-nowrap fw-medium">{{ email }}</p>
            </template>
            <template #item-totalAmount="{ totalAmount }">
              <p class="mb-0 text-nowrap fw-medium">${{ totalAmount }}</p>
            </template>
            <template #item-actionBtn="{ id }">
              <button @click="downloadInvoice(id)" class="btn btn-sm btn-theme-dark">
                <i class="mdi mdi-eye"></i>
                View
              </button>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </main>
  </Layout>
</template>
